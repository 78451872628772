import type { RootState } from '../../store'

export const selectEmail = (state: RootState) => state.authorization.email

export const selectCurrentStep = (state: RootState) => state.authorization.currentStep

export const selectIsCheckedEmail = (state: RootState) => state.authorization.isCheckedEmail

export const selectIsOpenAuthorizationPopup = (state: RootState) => state.authorization.isOpenAuthorizationPopup

export const selectIsLoadingAuth = (state: RootState) => state.authorization.isLoadingAuth

export const selectIsLoggedIn = (state: RootState) => state.authorization.isLoggedIn

export const selectUser = (state: RootState) => state.authorization?.user

export const selectIsExcludePrivacy = (state: RootState) => state.authorization.isExcludePrivacy

export const selectMessageLogin = (state: RootState) => state.authorization.message || ''
