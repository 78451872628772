import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { selectIsLoggedIn } from '@redux/features/authorization/authorization.selectors'
import { logout } from '@redux/features/authorization/thunks'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

import routes, { paths } from '@constants/routes/routes'

export const useLogOut = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const dispatch = useAppDispatch()
  const router = useRouter()

  const logOutHandler = useCallback(() => {
    if (!isLoggedIn) return
    void dispatch(logout())

    if (router.pathname.includes(paths.order)) {
      void router.push(routes.rootPath)
    }
  }, [dispatch, isLoggedIn, router])

  return { isLoggedIn, logOutHandler }
}
