import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

import { setIsLoggedIn } from '@redux/features/authorization/authorization.slice'
import { setPageInteraction } from '@redux/features/commonApp/commonApp.slice'
import { useLazyGetUserInfoQuery } from '@redux/features/user/user.api'
import { useAppDispatch } from '@redux/hooks'

import { useUserId } from '@hooks/user/useUserId/useUserId'

export const useCheckAuth = () => {
  const userId = useUserId()()
  const location = usePathname()
  const [getUserdata, { data }] = useLazyGetUserInfoQuery()
  const dispatch = useAppDispatch()

  useEffect(() => {
    userId && void getUserdata(userId)
  }, [location, getUserdata, userId])

  useEffect(() => {
    const value = !!data
    value && dispatch(setPageInteraction(value))
    dispatch(setIsLoggedIn(value))
  }, [data, dispatch])
}
