import { selectUser } from '@redux/features/authorization/authorization.selectors'
import { useAppSelector } from '@redux/hooks'

import authStorage from '@storageServices/storageEntities/auth'

export const useUserId = () => {
  const user = useAppSelector(selectUser)

  return () => user?.current_user.uid || authStorage.getValueByKey('userId')
}
